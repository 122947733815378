
                    @import "@/base/global-css/_sass-includes.scss";
                

.Header {
	border-block-start: 0 !important;
}
.Page--redLine {
	border-block-start: 4px solid $brand-color-red;
}
.Header .Header-list--dropdown .Header-list-heading .Header-list-heading-label {
	@include breakpoint(medium) {
		@include overrideVisuallyHidden;
		@include text(3);
		margin-inline-start: $space-xs;
		margin-inline-end: $space-xs;
		text-decoration: none;
	}
}
.Header .Header-main-menu-button span {
	@include visuallyHidden;
}
.Heading--center {
  display: block;
}
.HeaderIdentity-logo {
  padding: 2rem 1rem !important;
}

@include breakpoint(medium) {
	.Header-main {
		margin-inline-end: $space-md;
	}
	.Header.Header--minimal .Header-main-menu-panel .Header-group--secondary {
		column-gap: $space-md !important;
	}
	.Header.Header--minimal .Header-main-menu-panel .Header-link {
		padding-block: $space-sm !important;
	}
}

.graybox {
  @include inset-responsive;
  background-color: $brand-color-sand-2;
}

.sidebar {
  @include breakpoint(medium) {
    max-width: 16rem;
  }
}
.columnPadding {
  @include breakpoint(medium) {
    padding-left: $space-md;
    padding-right: $space-md;
  }
}

.Page-section-inner--narrow {
  max-width: 28rem;
}
.u-text-muted {
  color: $color-text-muted;
}
.formFlex {
	@include breakpoint(medium) {
		display: flex;
		flex-wrap: nowrap;
		gap: $space-sm;
	}
  	.InputText {
    	width: 100%;
  	}
}

.InputText--error {
  border-color: $color-error-4 !important;
  border-width: 2px !important;
}
.FormHelp--error {
  color: $color-error-3;
}
#suggestion-list button {
  color: $color-link;
}
.InputText--bold .InputText-field {
  font-weight: bold;
}

.DefinitionList {
  .DefinitionList-valueMuted {
    @include text(3);
  }
  .DefinitionList-keyEmphasis {
    font-weight: bold;
    @include text(5);
    @include stack-md;
  }
}

.Button--flex {
  display: inline-flex;
  align-items: center;
  gap: $space-sm;
}

.Button--error.Button--hollow {
  color: $color-alert-3;
  border-color: $color-alert-3;
  &:hover,
  &:active,
  &:focus {
    color: $color-alert-4;
    border-color: $color-alert-4;
  }
}

.Button--error[disabled] {
  background-color: $color-alert-3;
  color: $color-white;
  border-color: $color-alert-3;
  opacity: 0.5;
  &:hover {
    background-color: $color-alert-4;
  }
}

.error-message {
  color: $color-error-4;
  font-weight: bold;
}
.Button, .InputText .InputText-field, .InputSelect .InputSelect-select {
	border-radius: .5rem !important;
}
.Button--huge {
	@include inset-md;
	@include text(5);
}
.visuallyHiddenForMobile {
	@include visuallyHidden;
	@include breakpoint(medium) {
		@include overrideVisuallyHidden;
	}
}
.Header {
	border-block-end: 0 !important;
	.Header-link.Header-link--button {
		background-color: $color-secondary-4 !important;
	}
}
